import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Token } from '../../models/token.model';
import { TokenLocalStorage } from '../../services/token-storage.service';
import { auth } from '../auth.constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private tokenLocalStorage: TokenLocalStorage) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.endsWith(auth.URL_AUTH)) {
      if (!localStorage.getItem('dca_token')) {
        const httpOptions = {
          headers: new HttpHeaders({
            ['Content-Type']: auth.CONTENT_TYPE_DEFAULT,
            ['authorization']: this.tokenLocalStorage.get() ? this.tokenLocalStorage.get().token : '',
            ['tokenVeDi']: localStorage.getItem('token') ? localStorage.getItem('token') : '',
            ['refreshTokenVeDi']: localStorage.getItem('refreshToken') ? localStorage.getItem('refreshToken') : ''
          })
        };
        req = req.clone(httpOptions);
      }
      return next.handle(req);
    }

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': auth.CONTENT_TYPE_DEFAULT }),
      withCredentials: true,
      observe: 'response' as 'response'
    };

    req = req.clone(httpOptions);
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (req.url.endsWith(auth.URL_AUTH) && event instanceof HttpResponse) {
          const t = new Token();
          t.token = event.headers.get(auth.HEADER_NAME_AUTH);
          this.tokenLocalStorage.set(t);
        }
      })
    );
  }
}
