import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  OnDestroy,
  QueryList,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { ControlMessageComponent } from './control-message.component';
import { Subscription } from 'rxjs';
import { ErrorMessages } from './custom-validators';

@Component({
  selector: 'dca-control-messages',
  template: `
    <div class="invalid-feedback" *ngIf="control.invalid && (control.dirty || control.touched || submit)">
      <p *ngIf="message">{{message}}</p>
      <ng-content></ng-content>
    </div>`,
  styleUrls: ['./control-message.component.scss']
})
export class ControlMessagesComponent implements AfterContentInit, OnDestroy, OnChanges {
  @Input() submit: boolean;
  @Input() control: FormControl | AbstractControl;
  @ContentChildren(ControlMessageComponent) controlMessages: QueryList<ControlMessageComponent> = null;
  message: string;
  private statusChangesSubscription: Subscription;

  get errorMessage() {
    let mesagge = null;
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName)) {
        mesagge = ErrorMessages.messageOf(propertyName, this.control.errors[propertyName]);
      }
    }
    return mesagge;
  }

  public static validarDetalleFormulario(form: FormArray) {
    for (const obj in form.controls) {
      ControlMessagesComponent.validateForm(form.get(obj) as FormGroup);
    }
  }

  public static validateForm(form: FormGroup) {
    for (const obj in form.controls) {
      if (form.get(obj).errors != null) {
        form.get(obj).markAsDirty();
        form.get(obj).updateValueAndValidity();
      }
    }
    document.body.scrollTop = 0;
  }

  public static valdiateInput(campos: FormControl[]): boolean {
    let bool = true;
    for (const campo of campos) {
      if (campo.errors) {
        campo.markAsDirty();
        campo.updateValueAndValidity();
        bool = false;
        document.body.scrollTop = 0;
      }
    }
    return bool;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control && changes.control.currentValue) {
      this.control = changes.control.currentValue;
      this.initControl();
    }
  }

  ngAfterContentInit(): void {
    this.initControl();
  }

  ngOnDestroy() {
    this.statusChangesSubscription.unsubscribe();
  }

  private initControl(): void {
    this.getErrorMensaje(true);
    this.statusChangesSubscription =
      this.control
        .statusChanges
        .subscribe(() => {
          this.getErrorMensaje(false);
        });
  }

  getErrorMensaje(first?: boolean) {
    this.message = '';
    if (!first) {
      this.controlMessages.forEach((controlMessage) => controlMessage.show = false);
    }
    if (this.control.invalid) {
      const errors = Object.keys(this.control.errors);
      const firstErrorMessageComponent = !first ? this.controlMessages.find((controlMessage) => {
        return controlMessage.showsErrorIncludedIn(errors);
      }) : null;
      if (firstErrorMessageComponent) {
        firstErrorMessageComponent.show = true;
      } else {
        this.message = this.errorMessage;
      }
    }
  }
}
