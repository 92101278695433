import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { auth } from '../core/auth.constants';
import { Menu } from '../models/menu.model';
import { Sesion } from '../models/sesion.model';
import { TokenService } from './token.service';
import { MatDialog } from '@angular/material/dialog';
import { CustomResponseModel } from '../models/custom-response.model';
import { environment } from '../../environments/environment';
import { Token } from '../models/token.model';
import { CryptoService } from './crypto.service';

@Injectable()
export class AuthService {
  public menuChanged = new Subject<Menu[]>();
  public sessionChanged = new Subject<Sesion>();
  public perfilChanged = new Subject<Sesion>();
  public navBarChanged = new Subject<any>();
  private currentSession: Sesion = null;
  public currentMenu: Menu[] = null;
  private controllerName = 'security';
  public subject = new BehaviorSubject<string>('');

  constructor(private tokenService: TokenService, private dialog: MatDialog, private http: HttpClient, private cryptoService: CryptoService) {}

  getSession(): Sesion {
    return this.currentSession;
  }

  getNavBar(): Observable<any> {
    return this.navBarChanged;
  }

  setCurrentNavBar(visible: boolean): void {
    this.navBarChanged.next(visible);
  }

  getCurrentMenu(): Menu[] {
    return this.currentMenu;
  }

  isAuthenticated(): boolean {
    return !this.tokenService.isTokenExpired();
  }

  setCurrentSession(): void {
    if (!this.currentSession) {
      if (localStorage.getItem('dca_token') !== null) {
        this.tokenService.get()?.subscribe((t) => {
          this.currentSession = t?.sesion;
          this.currentSession.userType = t?.sesion.userType;
          this.currentSession.user.userType = this.getUserType();
          this.getMenu(+this.currentSession.user.id, this.currentSession.user.userType && this.currentSession.user.userType.id ? this.currentSession.user.userType.id : this.currentSession.userType[0]?.id).subscribe((menu) => {
            this.menuChanged.next(menu);
          });
        });
      }
    }
    this.sessionChanged.next(this.currentSession);
  }

  permissions(idUsuario: number, idTipoUsuario: number): Observable<any> {
    return this.http.post(`${this.controllerName}/permissions`, { idUsuario, idTipoUsuario });
  }

  verificarLoginVeDi(): Observable<string> {
    try {
      let result: Observable<string>;
      let pathname = '/padrones/consulta-qr/';
      result = new Observable<string>((observer) => {
        return observer.next('OK');
      });

      let search = window.location.search;
      if (search.startsWith('?')) {
        localStorage.removeItem('auth_dca_user');
        localStorage.removeItem('dca_token');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');

        search = search.substring(1);
        const searchParams = new URLSearchParams(search);
        const sesionQueryString = searchParams.get('sesionid'); // obtengo el sesion id y llamo al WS
        const body = {
          sesionId: sesionQueryString
        };

        this.http.post<any>(`${this.controllerName}/login`, body).subscribe((res) => {
          if (res) {
            localStorage.setItem('token', res.token);
            localStorage.setItem('refreshToken', res.refreshToken);

            const token = localStorage.getItem('token');
            if (
              token === undefined ||
              token === null ||
              token === 'undefined' ||
              token === ''
            ) {
              this.cerrarSesion();
              // Acá se entra cuando se esta cargando con un asession activa
            } else {
              window.history.replaceState(null, '', window.location.pathname);
              this.loguearUsuario().subscribe({
                next: (user) => {
                  localStorage.setItem('dca_token', user);
                  this.currentSession = null;
                  this.setCurrentSession();
                },
                error: () => {
                  this.cerrarSesion();
                }
              });
            }
          }
        });

        return result;
      } else if ((pathname + window.location.pathname.split('/')[3]) === window.location.pathname) {
        result = new Observable<string>((observer) => {
          return observer.next('OK');
        });

        return result;
      } else if (localStorage.getItem('token') !== null) {
        this.loguearUsuario().subscribe({
          next: () => {
            this.changePerfil();
          },
          error: () => {
            this.cerrarSesion();
            window.location.href = environment.vediURl;
          }
        });

        return result;
      } else {
        result = new Observable<string>((observer) => {
          this.setCurrentSession();
          return observer.next('NOT_OK');
        });
        return result;
      }
    } catch (error) {
      console.log(error);
      this.cerrarSesion();
    }
  }

  loguearUsuario() {
    try {
      const body = {
        tokenVeDi: localStorage.getItem('token')
      };
      return this.http.post<any>(`${this.controllerName}/obtenerUsuario`, body);
    } catch (error) {
      this.cerrarSesion();
    }
  }

  changePerfil() {
    try {
      const body = {
        tokenVeDi: localStorage.getItem('token'),
        user: this.getSession().user
      };
      this.http.post<any>(`${this.controllerName}/obtenerUsuario`, body).subscribe((res) => {
        localStorage.setItem('dca_token', res);
        this.currentSession = null;
        this.setCurrentSession();
      });
    } catch (e) {
      this.cerrarSesion();
    }
  }

  cerrarSesion() {
    const body = {
      tokenVeDi: localStorage.getItem('token')
    };
    this.http.post<boolean>(`${this.controllerName}/cerrarSesion`, body)
      .subscribe((res) => {
        if (res) {
          localStorage.removeItem('auth_dca_user');
          localStorage.removeItem('dca_token');
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          // window.open(environment.vediURl);
          window.location.reload();
        }
      });
  }

  decrypt(encrypted: string): any {
    const descrypted = encrypted ? CryptoJS.AES.decrypt(encrypted, auth.ENCRYPT_PASS).toString(CryptoJS.enc.Utf8) : null;
    return JSON.parse(descrypted);
  }

  decodeToken(tokenVedi) {
    const token = new Token();
    const base64Url = tokenVedi.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const payLoad = JSON.parse(jsonPayload);
    token.expirationDate = payLoad.Expiracion;

    return token;
  }

  encrypt(decrypted: string) {
    return CryptoJS.AES.encrypt(decrypted, auth.ENCRYPT_PASS).toString();
  }

  logout(): void {
    this.sessionChanged.next(null);
    localStorage.removeItem('auth_dca_user');
    localStorage.removeItem('dca_token');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    window.open(environment.vediURl);
  }

  getMenu(idUsuario: number, idTipoUsuario: number): Observable<Menu[]> {
    const body = {
      idUsuario,
      idTipoUsuario
    };
    return this.http.post<Menu[]>(`${this.controllerName}/menu`, body);
  }

  getIdUser(): number {
    return + this.getSession().user.id;
  }

  getUserType(): any {
    const userToken: string = localStorage.getItem('auth_dca_user');
    const userType: any = userToken ? this.decrypt(userToken) : undefined;
    return userType;
  }

  validateToken(token: string): Observable<CustomResponseModel> {
    const body = {
      token
    };
    return this.http.post<CustomResponseModel>(`${this.controllerName}/validateToken`, body);
  }

  refreshToken(): Observable<any> {
    const body = {
      token: localStorage.getItem('refreshToken')
    };

    return this.http.post<any>(`${this.controllerName}/refreshToken`, body);
  }
}
