import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TipoUsuario} from '../../../models/tipo-usuario.model';

export interface DialogData {
  perfiles: TipoUsuario[];
  perfilActual: TipoUsuario;
}

@Component({
  selector: 'dca-perfiles-dialog',
  templateUrl: './perfiles-dialog.component.html',
  styleUrls: ['./perfiles-dialog.component.scss']
})
export class PerfilesDialogComponent implements OnInit {

  perfilSeleccionado = null;
  tipoUsuarioSeleccionado = null;

  constructor(public dialogRef: MatDialogRef<PerfilesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }

  ngOnInit(): void {
    this.perfilSeleccionado = this.data.perfilActual ? this.data.perfilActual.id : this.data.perfiles[0].id;
  }

  onNoClick(): void {
    this.data.perfiles.forEach((perfil) => {
      if (perfil.id === this.perfilSeleccionado) {
        this.tipoUsuarioSeleccionado = perfil;
      }
    });
    this.dialogRef.close(this.tipoUsuarioSeleccionado);
  }

  close() {
    this.dialogRef.close();
  }

}
