import { Routes } from '@angular/router';
import { LayoutComponent } from '../core/layout/layout/layout.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivateChild: [ ],
    children: [
      {
        path: 'home',
        redirectTo: '',
        pathMatch: 'full'
      },
      {
        path: '',
        loadChildren: () => import('../modules/components/home/home.module').then((h) => h.HomeModule)
      },
      {
        path: 'tramites-rmtsa',
        loadChildren: () => import('../modules/components/tramites-rmtsa/tramites-rmtsa.module').then((h) => h.TramitesRmtsaModule)
      },
      {
        path: 'tramites-rme',
        loadChildren: () => import('../modules/components/tramites-rme/tramites-rme.module').then((h) => h.TramitesRmeModule)
      },
      {
        path: 'tramites-rmdt',
        loadChildren: () => import('../modules/components/tramites-rmdt/tramites-rmdt.module').then((h) => h.TramitesRmdtModule)
      },
      {
        path: 'padrones',
        loadChildren: () => import('../modules/components/padrones/padrones.module').then((h) => h.PadronesModule)
      },
      {
        path: 'solicitudes',
        loadChildren: () => import('../modules/components/solicitudes/solicitudes.module').then((h) => h.SolicitudesModule)
      },
      {
        path: 'sam',
        loadChildren: () => import('../modules/components/sam/sam.module').then((h) => h.SamModule)
      },
      {
        path: 'vinculacion-dt',
        loadChildren: () => import('../modules/components/vinculacion-dt/vinculacion-dt.module').then((h) => h.VinculacionDtModule)
      },
      {
        path: 'inspecciones',
        loadChildren: () => import('../modules/components/inspecciones/inspecciones.module').then((h) => h.InspeccionesModule)
      },
      {
        path: 'usuarios',
        loadChildren: () => import('../modules/components/gestion-usuarios/gestion-usuarios.module').then((h) => h.GestionUsuariosModule)
      }
    ]
  },
  // Not found
  { path: '**', redirectTo: 'home' },
  { path: 'package.json', redirectTo: 'home' },
  { path: 'npm-shrinkwrap.json', redirectTo: 'home' }
];
