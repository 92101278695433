import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TipoSnackBarUtil } from '../shared/enums/TipoSnackBar.util';
import { MensajeSnackBar } from '../models/mensaje-snack-bar.model';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  close: string;

  constructor(
    private snackBar: MatSnackBar
  ) {

  }

  public openSnackBarError(mensaje: string) {
    this.snackBar.open(mensaje, this.close, {
      verticalPosition: 'top',
      panelClass: 'notify-error',
      duration: 5000
    });
  }

  public openSnackBarSuccess(mensaje: string) {
    this.snackBar.open(mensaje, this.close, {
      duration: 5000,
      verticalPosition: 'top',
      panelClass: 'notify-success'
    });
  }

  public openSnackBarWarning(mensaje: string) {
    this.snackBar.open(mensaje, this.close, {
      duration: 8000,
      verticalPosition: 'top',
      panelClass: 'notify-warning'
    });
  }

  public openArraySnackBar(messages: MensajeSnackBar[]) {
    if (messages instanceof Array) {
      messages.forEach((message, index) => {
        setTimeout(() => {
          if (message.tipo === TipoSnackBarUtil.ERROR) {
            this.openSnackBarError(message.mensaje);
          } else {
            this.openSnackBarSuccess(message.mensaje);
          }
        }, index * 2000);
      });
    }
  }
}
