import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { filter, share } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Token } from '../models/token.model';
import { TokenLocalStorage } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  protected token$: BehaviorSubject<Token> = new BehaviorSubject(null);

  constructor(private storage: TokenLocalStorage, private jwt: JwtHelperService) {
    this.publishStoredToken();
  }

  tokenChange(): Observable<Token> {
    return this.token$.pipe(
      filter((value) => !!value),
      share()
    );
  }

  set(token: Token): Observable<Token> {
    this.storage.set(token);
    this.publishStoredToken();
    return observableOf(token);
  }

  get(): Observable<Token> {
    const token = this.storage.get();
    return observableOf(token);
  }

  clear(): Observable<null> {
    this.storage.clear();
    this.publishStoredToken();
    return observableOf(null);
  }

  isTokenExpired(): boolean {
    return this.jwt.isTokenExpired(this.jwt.tokenGetter());
  }

  generateNewToken(token: Token) {

  }

  protected publishStoredToken(): void {
    this.token$.next(this.storage.get());
  }

  //
  // getCurrenToken(): string {
  //   return this.getCurrentSession();
  // }
}
