import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Menu } from '../../../models/menu.model';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { animateSubText, animateText, animateTextTraslate, onSideNavChange } from '../../../shared/animations/animations';
import { FilterCacheService } from '../../../services/filter-cache.service';

@Component({
  selector: 'dca-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [ './sidebar.component.scss' ],
  animations: [ onSideNavChange, animateText, animateTextTraslate, animateSubText ]
})
export class SidebarComponent implements OnInit {
  @Output() toggleSideBarClickOpcionMenu: EventEmitter<boolean> = new EventEmitter();
  menuItems: Menu[] = [];
  sidenav: MatSidenav;

  constructor(
    private router: Router,
    private authService: AuthService,
    private filterCacheService: FilterCacheService) {}

  ngOnInit(): void {
     this.subscribeMenu();
  }

  navigate(path: string) {
    if (path) {
      this.router.navigate([ `${path}` ]);
    }
  }

  activarItem(item: Menu) {
    this.menuItems.map((x) => {
      if (x.active && item.id !== x.id) {
        x.active = false;
      }
    });
    item.active = !item.active;
  }

  subscribeMenu() {
    this.authService.menuChanged.subscribe((menu) => {
      this.menuItems = menu;
    });
  }

  capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  onSidenavToggle() {
    this.filterCacheService.clear();
    this.toggleSideBarClickOpcionMenu.emit();
  }

  activarSubItem(subItem: Menu) {
    this.menuItems.map((x) => {
      x.subMenu?.map((s) => {
        if (s.active && subItem.id !== s.id) {
          s.active = false;
        }
      });
    });
    subItem.active = !subItem.active;
  }
}
