<mat-drawer-container>
  <mat-drawer mode="over" class="mat-elevation-z9 onSidebar sideBar" [opened]=sideBarOpen position="end" (openedChange)="sideBarChange($event)">
    <dca-sidebar [hidden]="!session" (toggleSideBarClickOpcionMenu)="sideBarToggler($event)"></dca-sidebar>
  </mat-drawer>

  <mat-drawer-content class="drawer">
    <dca-header (toggleSideBarMenuClick)="sideBarToggler($event)"></dca-header>

    <div style="min-height: 96vh;">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>

    <dca-footer></dca-footer>
  </mat-drawer-content>
</mat-drawer-container>
