import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SnackBarService } from '../../services/snack-bar.service';

@Injectable()
export class StatusResponseInterceptor implements HttpInterceptor {

  constructor(private router: Router, private snackBarService: SnackBarService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (req.url.endsWith('login') && event instanceof HttpResponse) {
            return event.headers;
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            switch (err.status) {
            case 400:
              this.snackBarService.openSnackBarError(err.error);
              break;
              case 401:
              this.snackBarService.openSnackBarError(err.error.message);
              this.router.navigate([ '/' ]);
              break;
            case 404:
              this.snackBarService.openSnackBarError(err.error);
              this.router.navigate([ '/' ]);
              break;
            case 409:
              this.snackBarService.openSnackBarError(err.error);
              this.router.navigate([ '/' ]);
              break;
            case 500:
              this.snackBarService.openSnackBarError(err.error);
              break;
            case 504:
              this.snackBarService.openSnackBarError('La operación no se pudo realizar, intente nuevamente.');
              break;
            default:
              break;
            }
          }
        }
      )
    );
  }
}
