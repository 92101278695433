<footer style="background: #efefef">
  <div class="container">
    <div class="row">

      <div class="col-sm-8 col-12 d-flex align-items-center">
        <div class="d-flex flex-column mt-4">
          <!-- Imágenes -->
          <div style="display: flex; align-items: center;">
            <img class="img-logo-multicolor" src="../../../../assets/img/cordoba-capital-logo.svg" alt="logo-multicolor-municipalidad-cba" height="60" width="100">
            <div style="width: 2px; height: 60px; background-color: #004b81; margin-left:15px"></div>
            <img src="../../../../assets/img/logo-muni.svg" alt="logo-celeste-municipalidad-cba" height="100" width="220" style="padding-left: 2em;">
          </div>
        </div>
      </div>

      <!-- Columna derecha -->
      <div class="col-sm-4 col-12">
        <!-- Redes sociales -->
        <div class="mt-4 mb-2">
          <span style="margin-right: 5px;">Seguinos en</span>
          <a href="https://www.facebook.com/MuniCba/" target="_blank">
            <img class="img-red-soc" src="assets/img/facebook.svg" alt="icono-facebook-municipalidad-cba">
          </a>
          <a href="https://www.instagram.com/municba/" target="_blank">
            <img class="img-red-soc"  src="assets/img/instagram.svg" alt="icono-instagram-municipalidad-cba">
          </a>
          <a href="https://twitter.com/MuniCba" target="_blank">
            <img class="img-red-soc"  src="assets/img/X.svg" alt="icono-twitter-municipalidad-cba">
          </a>
          <a href="https://www.youtube.com/user/cordobagovar" target="_blank">
            <img class="img-red-soc"  src="assets/img/youtube.svg" alt="icono-youtube-municipalidad-cba">
          </a>
        </div>

        <!-- Información adicional -->
        <div class="row">
          <div class="col">
            <span class="d-block">Municipalidad de Córdoba</span>
            <span class="d-block">Marcelo T. de Alvear 120, Córdoba.</span>
            <span class="d-block">República Argentina 0800-888-0404</span>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- Linea final -->
  <div>
    <div class="row" style="height: 10px; margin-top: 20px;">
      <div class="col-4" style="background-color: #e1a113"></div>
      <div class="col-4" style="background-color: #971b2f"></div>
      <div class="col-4" style="background-color: #009fe3"></div>
    </div>
  </div>

</footer>
