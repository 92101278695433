import { Component, Input } from '@angular/core';

@Component({
  selector: 'dca-messages',
  templateUrl: './messages.component.html',
  styleUrls: [ './messages.component.scss' ]
})
export class MessagesComponent {
  @Input()
  public messageType: number;
  @Input()
  public title: string;
  @Input()
  public message: string;
  @Input()
  public messageRed: string;

}
