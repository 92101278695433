import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Sesion } from 'src/app/models/sesion.model';
import { TokenLocalStorage } from 'src/app/services/token-storage.service';
import { PerfilesDialogComponent } from '../../../shared/dialogs/perfiles-dialog/perfiles-dialog.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'dca-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ]
})
export class HeaderComponent implements OnInit {
  @Output() toggleSideBarMenuClick: EventEmitter<boolean> = new EventEmitter();
  hide = true;
  userName = '';
  session: Sesion;

  constructor(
    private router: Router,
    private authService: AuthService,
    private storage: StorageMap,
    private dialog: MatDialog,
    private tokenLocalStorage: TokenLocalStorage) {}

  ngOnInit(): void {
    this.authService.sessionChanged.subscribe((s) => {
      this.session = s;

      if (!this.session.user.userType) {
        if (this.session.userType && this.session.userType.length === 1) {
          this.session.user.userType = this.session.userType[0];
          this.authService.perfilChanged.next(this.session);
          this.navigate('');
          localStorage.setItem('auth_dca_user', this.authService.encrypt(JSON.stringify(this.session.user.userType)));
        } else if (this.session.userType && this.session.userType.length > 1) {
          this.cambiarPerfil();
        }
      }
    });
  }

  navigate(path: string) {
    this.router.navigate([ path ]);
  }

  cerrarSesion() {
    this.authService.cerrarSesion();
  }

  volverAVedi() {
    window.location.href = environment.vediURl;
  }

  cambiarPerfil(): void {
    const dialogRef = this.dialog.open(PerfilesDialogComponent, {
      width: '400px',
      disableClose: true,
      data: {
        perfiles: this.session.userType,
        perfilActual: this.session.user.userType
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.session.user.userType = result;
        localStorage.setItem('auth_dca_user', this.authService.encrypt(JSON.stringify(this.session.user.userType)));
        this.authService.changePerfil();
        this.authService.perfilChanged.next(this.session);
        this.navigate('');
      }
    });
  }

  public toggleSideBar() {
    this.toggleSideBarMenuClick.emit(true);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }
}
