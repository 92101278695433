import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './shared/material-module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { RoutesModule } from './routes/routes.module';
import { LayoutModule } from './core/layout/layout/layout.module';
import { MomentModule } from 'ngx-moment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QrCodeModule } from 'ng-qrcode';
import { SamModule } from './modules/components/sam/sam.module';

const maskConfig: Partial<IConfig> = {
  validation: false
};

@NgModule({
  declarations: [ AppComponent ],
  imports: [
    SharedModule,
    LayoutModule,
    MaterialModule,
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    RoutesModule,
    MomentModule,
    FontAwesomeModule,
    NgxMaskModule.forRoot(maskConfig),
    NgxMaterialTimepickerModule.setOpts('es-ES'),
    QrCodeModule,
    SamModule
  ],
  exports: [],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {}
