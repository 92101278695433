import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BarrioModel } from '../../../../models/barrio.model';
import { CalleModel } from '../../../../models/calle.model';
import { SupportService } from '../../../../services/support.service';
import {CustomValidators} from '../../../../shared/custom-validators/custom-validators';
import {PagoTasasService} from '../../../../services/pago-tasas.service';
import {PersonasSamFilter} from '../../../../shared/filtros/personas-sam.filter';

@Component({
  selector: 'dca-registro-personas',
  templateUrl: './registro-personas.component.html',
  styleUrls: [ './registro-personas.component.scss' ]
})
export class RegistroPersonasComponent implements OnInit {

  formPersona: FormGroup;
  generos = [
    {id: 'F', descripcion: 'Femenino'},
    {id: 'M', descripcion: 'Masculino'},
    {id: 'I', descripcion: 'Sin determinar'}
  ];
  tiposDocumentos = [
    {id: 0, descripcion: 'Sin datos'},
    {id: 1, descripcion: 'L.E.'},
    {id: 2, descripcion: 'L.C.'},
    {id: 3, descripcion: 'D.N.I.'},
    {id: 4, descripcion: 'C.I.'},
    {id: 8, descripcion: 'Pasaporte'}
    ];
  estadosCiviles = [
    {id: 0, descripcion: 'No definido'},
    {id: 1, descripcion: 'Soltero'},
    {id: 2, descripcion: 'Casado'},
    {id: 3, descripcion: 'Concubinato'},
    {id: 4, descripcion: 'Divorciado'},
    {id: 5, descripcion: 'Separado'},
    {id: 6, descripcion: 'Viudo'}
  ];
  condicionesIVA = [
    {id: 0, descripcion: 'No definido'},
    {id: 1, descripcion: 'Consumidor final'},
    {id: 2, descripcion: 'Responsable inscripto'},
    {id: 3, descripcion: 'Responsable no inscripto'},
    {id: 4, descripcion: 'No responsable'},
    {id: 5, descripcion: 'Exento'},
    {id: 6, descripcion: 'Monotributista'}
  ];
  estadosIIBB = [
    {id: 'A', descripcion: 'Activo'},
    {id: 'B', descripcion: 'Baja'},
    {id: 'E', descripcion: 'Exento'},
    {id: 'N', descripcion: 'No inscripto'},
    {id: 'S', descripcion: 'Solicitud cese'},
    {id: 'R', descripcion: 'Recepción cese'}
  ];
  clasificacionesIIBB = [
    {id: 0, descripcion: 'No definido'},
    {id: 1, descripcion: 'Local'},
    {id: 2, descripcion: 'Convenio multilateral'},
  ];
  paises = [
    {id: 1, descripcion: 'Argentina'},
  ];
  provincias = [
    {id: 4, descripcion: 'Córdoba'},
  ];
  localidades = [
    {id: 1, descripcion: 'Córdoba', codigoPostal: '5000'},
  ];
  barrios: BarrioModel[] = [];
  barriosFiltrados: BarrioModel[] = [];
  calles: CalleModel[] = [];
  callesFiltradas: CalleModel[] = [];

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private supportService: SupportService,
              private pagoTasasService: PagoTasasService) {
    this.formPersona = formBuilder.group({
      apellidoNombre: ['', [Validators.required] ],
      genero: ['', Validators.required ],
      tipoDocumento: ['', Validators.required],
      numeroDocumento: ['', [Validators.required, CustomValidators.number, Validators.maxLength(8)]],
      cuil: ['', [Validators.required, CustomValidators.number, Validators.maxLength(11)]],
      fechaNacimiento: ['', [Validators.required]],
      telefono: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(13)]],
      email: ['', [Validators.required, Validators.email]],
      estadoCivil: ['', Validators.required],
      pais: [],
      provincia: [],
      localidad: [],
      barrio: ['', Validators.required],
      calle: ['', Validators.required],
      numero: [''],
      piso: [''],
      departamento: [''],
      condicionIVA: ['', Validators.required],
      estadoIIBB: ['', Validators.required],
      clasificacionIIBB: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.formPersona.controls.genero.setValue(this.generos[0].id);
    this.formPersona.controls.tipoDocumento.setValue(this.tiposDocumentos[3].id);
    this.formPersona.controls.estadoCivil.setValue(this.estadosCiviles[1].id);
    this.formPersona.controls.pais.setValue(this.paises[0].id);
    this.formPersona.controls.provincia.setValue(this.provincias[0].id);
    this.formPersona.controls.localidad.setValue(this.localidades[0].id);
    this.formPersona.controls.condicionIVA.setValue(this.condicionesIVA[0].id);
    this.formPersona.controls.estadoIIBB.setValue(this.estadosIIBB[0].id);
    this.formPersona.controls.clasificacionIIBB.setValue(this.clasificacionesIIBB[0].id);
    this.formPersona.controls.pais.disable();
    this.formPersona.controls.provincia.disable();
    this.formPersona.controls.localidad.disable();
    this.supportService.getBarriosSam().subscribe((barrios) => {
      if (barrios) {
        this.barrios = barrios;
        this.formPersona.controls.barrio.valueChanges.subscribe(barrio => {
          this.barriosFiltrados = this.filterBarrios(barrio);
        });
      }
    });

    this.supportService.getCallesSam().subscribe((calles) => {
      if (calles) {
        this.calles = calles;
        this.formPersona.controls.calle.valueChanges.subscribe(calle => {
          this.callesFiltradas = this.filterCalles(calle);
        });
      }
    });
  }

  filterBarrios(busqueda: string) {
    return this.barrios.filter(value =>
      value.nombre.toLowerCase().indexOf(busqueda.toLowerCase()) === 0);
  }

  filterCalles(busqueda: string) {
    return this.calles.filter(value =>
      value.nombre.toLowerCase().indexOf(busqueda.toLowerCase()) === 0);
  }

  cancelar(): void {
    this.router.navigate(['']);
  }

  guardar(): void {
    if (this.formPersona.valid) {
      const data: PersonasSamFilter = new PersonasSamFilter();
      const localidad = this.obtenerLocalidad(this.formPersona.controls.localidad.value);
      const barrio = this.obtenerBarrio(this.formPersona.controls.barrio.value);
      const calle = this.obtenerCalle(this.formPersona.controls.calle.value);

      data.apellidoNombre = this.formPersona.controls.apellidoNombre.value;
      data.genero = this.formPersona.controls.genero.value;
      data.tipoDocumento = this.formPersona.controls.tipoDocumento.value;
      data.numeroDocumento = this.formPersona.controls.numeroDocumento.value;
      data.cuil = this.formPersona.controls.cuil.value;
      data.fechaNacimiento = this.formPersona.controls.fechaNacimiento.value;
      data.telefono = this.formPersona.controls.telefono.value;
      data.email = this.formPersona.controls.email.value;
      data.estadoCivil = this.formPersona.controls.estadoCivil.value;
      data.pais = this.formPersona.controls.pais.value;
      data.provincia = this.formPersona.controls.provincia.value;
      data.localidad = localidad.id;
      data.codigoPostal = localidad.codigoPostal;
      data.idBarrio = barrio.idSam;
      data.barrio = barrio.nombre;
      data.idCalle = calle.idSam;
      data.calle = calle.nombre;
      data.numero = this.formPersona.controls.numero.value;
      data.piso = this.formPersona.controls.piso.value;
      data.departamento = this.formPersona.controls.departamento.value;
      data.condicionIVA = this.formPersona.controls.condicionIVA.value;
      data.estadoIIBB = this.formPersona.controls.estadoIIBB.value;
      data.clasificacionIIBB = this.formPersona.controls.clasificacionIIBB.value;

      this.pagoTasasService.generarUsuarioSam(data).subscribe(result => {
        if (result) {
          this.router.navigate(['']);
        }
      });
    }
  }

  obtenerLocalidad(id): any {
    let localidadEncontrada = null;
    this.localidades.forEach((localidad) => {
      if (localidad.id == id) {
        localidadEncontrada = localidad;
      }
    });
    return localidadEncontrada;
  }

  obtenerBarrio(nombre): any {
    let barrioEncontrado = null;
    this.barrios.forEach((barrio) => {
      if (barrio.nombre == nombre) {
        barrioEncontrado = barrio;
      }
    });
    return barrioEncontrado;
  }

  obtenerCalle(nombre): any {
    let calleEncontrada = null;
    this.calles.forEach((calle) => {
      if (calle.nombre == nombre) {
        calleEncontrada = calle;
      }
    });
    return calleEncontrada;
  }
}
