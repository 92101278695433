export class PersonasSamFilter {
  apellidoNombre: string;
  genero: string;
  tipoDocumento: string;
  numeroDocumento: string;
  cuil: string;
  fechaNacimiento: string;
  telefono: string;
  email: string;
  estadoCivil: string;
  pais: string;
  provincia: string;
  localidad: string;
  codigoPostal: string;
  idBarrio: string;
  barrio: string;
  idCalle: string;
  calle: string;
  numero: string;
  piso: string;
  departamento: string;
  condicionIVA: string;
  estadoIIBB: string;
  clasificacionIIBB: string;

  constructor(apellidoNombre?: string,
              genero?: string,
              tipoDocumento?: string,
              numeroDocumento?: string,
              cuil?: string,
              fechaNacimiento?: string,
              telefono?: string,
              email?: string,
              estadoCivil?: string,
              pais?: string,
              provincia?: string,
              localidad?: string,
              codigoPostal?: string,
              idBarrio?: string,
              barrio?: string,
              idCalle?: string,
              calle?: string,
              numero?: string,
              piso?: string,
              departamento?: string,
              condicionIVA?: string,
              estadoIIBB?: string,
              clasificacionIIBB?: string) {
    this.apellidoNombre = apellidoNombre;
    this.genero = genero;
    this.tipoDocumento = tipoDocumento;
    this.numeroDocumento = numeroDocumento;
    this.cuil = cuil;
    this.fechaNacimiento = fechaNacimiento;
    this.telefono = telefono;
    this.email = email;
    this.estadoCivil = estadoCivil;
    this.pais = pais;
    this.provincia = provincia;
    this.localidad = localidad;
    this.codigoPostal = codigoPostal;
    this.idBarrio = idBarrio;
    this.barrio = barrio;
    this.idCalle = idCalle;
    this.calle = calle;
    this.numero = numero;
    this.piso = piso;
    this.departamento = departamento;
    this.condicionIVA = condicionIVA;
    this.estadoIIBB = estadoIIBB;
    this.clasificacionIIBB = clasificacionIIBB;
  }
}
