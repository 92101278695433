import { ElementRef, Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FocusInvalidService {
  focusInvalid(form: FormGroup, el: ElementRef) {
    for (const key of Object.keys(form.controls)) {
      if (form.controls[key].invalid) {
        const invalidControl = el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        invalidControl.focus();
        break;
      }
    }
  }

  focusInvalidControl(control: AbstractControl, controlName: string, el: ElementRef) {
    if (control.invalid) {
      const invalidControl = el.nativeElement.querySelector('[formcontrolname="' + controlName + '"]');
      invalidControl.focus();
    }
  }

  touchForm(form: FormGroup, el: ElementRef) {
    for (const key of Object.keys(form.controls)) {
      form.controls[key].markAsTouched();
      form.controls[key].updateValueAndValidity();
    }
  }

  clearError(form: FormGroup) {
    for (const key of Object.keys(form.controls)) {
      form.controls[key].setErrors(null);
    }
  }
}
