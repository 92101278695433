import { Token } from '../models/token.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParserTokenService {
  constructor(private jwt: JwtHelperService) {}

  wrap(token: Token): string {
    return token.token?.toString();
  }

  unwrap(value: string): Token {
    const token: Token = this.parseToken(value);
    return token;
  }

  protected parseToken(value): Token {
    try {
      const jsonValue = this.jwt.decodeToken(value);
      const t = {
        sesion: {
          userType: jsonValue.userType,
          user: {
            id: jsonValue.user.id,
            email: jsonValue.user.email,
            lastName: jsonValue.user.lastName,
            firstName: jsonValue.user.firstName,
            userName: jsonValue.user.userName,
            completeName: undefined,
            name: undefined,
            userType: jsonValue.user.userType,
            dni: jsonValue.user.dni,
            cuil: jsonValue.user.cuil,
            idSexo: jsonValue.user.idSexo,
            fechaNacimiento: jsonValue.user.fechaNacimiento,
            domicilioDireccion: jsonValue.user.domicilioDireccion,
            domicilioAltura: jsonValue.user.domicilioAltura,
            domicilioPiso: jsonValue.user.domicilioPiso,
            domicilioDepto: jsonValue.user.domicilioDepto,
            domicilioTorre: jsonValue.user.domicilioTorre,
            domicilioCodigoPostal: jsonValue.user.domicilioCodigoPostal,
            domicilioBarrioNombre: jsonValue.user.domicilioBarrioNombre,
            domicilioCiudadNombre: jsonValue.user.domicilioCiudadNombre,
            domicilioProvinciaNombre: jsonValue.user.domicilioProvinciaNombre,
            domicilioPais: jsonValue.user.domicilioPais,
            telefonoCelular: jsonValue.user.telefonoCelular,
            tieneRepresentadoSeleccionado: jsonValue.user.tieneRepresentadoSeleccionado,
            representado: jsonValue.user.representadoSeleccionado
          }
        },
        token: value,
        creationDate: new Date(jsonValue.iat),
        expirationDate: this.jwt.getTokenExpirationDate(value)
      };
      t.sesion.user.completeName = `${jsonValue.user.firstName} ${jsonValue.user.lastName}`;
      return t;
    } catch (e) {}
    return null;
  }

  protected generateNewToken(value): Token {
    try {
      const jsonValue = this.jwt.decodeToken(value);
      const t = {
        sesion: {
          userType: jsonValue.userType,
          user: {
            id: jsonValue.user.id,
            email: jsonValue.user.email,
            lastName: jsonValue.user.lastName,
            firstName: jsonValue.user.firstName,
            userName: jsonValue.user.userName,
            completeName: undefined,
            name: undefined,
            userType: jsonValue.user.userType,
            dni: jsonValue.user.dni,
            cuil: jsonValue.user.cuil,
            idSexo: jsonValue.user.idSexo,
            fechaNacimiento: jsonValue.user.fechaNacimiento,
            domicilioDireccion: jsonValue.user.domicilioDireccion,
            domicilioAltura: jsonValue.user.domicilioAltura,
            domicilioPiso: jsonValue.user.domicilioPiso,
            domicilioDepto: jsonValue.user.domicilioDepto,
            domicilioTorre: jsonValue.user.domicilioTorre,
            domicilioCodigoPostal: jsonValue.user.domicilioCodigoPostal,
            domicilioBarrioNombre: jsonValue.user.domicilioBarrioNombre,
            domicilioCiudadNombre: jsonValue.user.domicilioCiudadNombre,
            domicilioProvinciaNombre: jsonValue.user.domicilioProvinciaNombre,
            domicilioPais: jsonValue.user.domicilioPais,
            telefonoCelular: jsonValue.user.telefonoCelular,
            tieneRepresentadoSeleccionado: jsonValue.user.tieneRepresentadoSeleccionado,
            representado: jsonValue.user.representadoSeleccionado
          }
        },
        token: value,
        creationDate: new Date(jsonValue.iat),
        expirationDate: this.jwt.getTokenExpirationDate(value)
      };
      t.sesion.user.completeName = `${jsonValue.user.firstName} ${jsonValue.user.lastName}`;
      return t;
    } catch (e) {}
    return null;
  }
}
