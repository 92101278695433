import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterCacheService {
  private localUrl: string;
  private localCache: any;

  constructor() {
    this.localUrl = null;
    this.localCache = null;
  }

  init(url: string): void {
    if (!this.localUrl || (this.localUrl && this.localUrl !== url)) {
      this.localUrl = url;
      this.localCache = null;
    }
  }

  get(): any {
    return this.localCache;
  }

  set(valor: any): void {
    this.localCache = valor;
  }

  clear(): void {
    this.localUrl = null;
    this.localCache = null;
  }
}
