import { Sesion } from './sesion.model';

export class Token {
  token: string;
  sesion: Sesion;
  creationDate: Date;
  expirationDate: Date;

  constructor() {
    this.token = undefined;
    this.sesion = undefined;
    this.creationDate = undefined;
    this.expirationDate = undefined;
  }
}
