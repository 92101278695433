import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';

const NUMBER_REGEXP = /^\d+$/;
const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const DECIMAL_REGEXP = /^-?\d+(\.?\d+)?$/;
const DECIMAL_WITH_TWO_DIGITS_REGEXP = /^[0-9]+([.][0-9]{0,2})?$/;
const TEXT_REGEXP = /[a-zA-Z ]{2,60}/;
const TEXT_NUMBER_REGEXP = /[0-9a-zA-Z]/g;
const FILENAME_REGEXP = /^([a-zA-Z_\-\d])+$/;
const PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&,._-])[A-Za-z\d@$!%*?&,._-]{8,20}$/;
const DATE_REGEXP = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
const WHITESPACE = /^[\s]*$/;
const DOCUMENT = /^\d{7}(?:\d{1})?$/;
const CUIL = /^(20|23|24|27|30|33)([0-9]{9}|-[0-9]{8}-[0-9]{1})$/;
const PERCENTAGE = /^(100(\.0{1,2})?|([0-9]?[0-9](\.[0-9]{1,2})?))$/;
const NUMBER_PHONE_REGEXP = /^(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/;
const PATENTE_ARG = /^([a-zA-Z]{2,3})([0-9]{3})([a-zA-Z]{2})?$/;
const DECIMAL_POSITIVO_CON_COMA = /^\d*(\,[0-9]+)?$/;
const NOMENCLATURA_CATASTRO = /^([0-9]{2}[0-9]{2}[0-9]{3}[0-9]{3})$/;
const UNIDAD_CATASTRO = /^([0-9]{5})$/;
const TEXTO_VALIDO = /^([0-9a-zA-Z `()¡!'áéíóúÁÉÍÓÚ.&,ñÑ-])*$/;
const SOLO_NUMEROS = /^([0-9])*$/;
const SOLO_NUMEROS_NO_NEGATIVOS = /^(?!-)$/;
const KANJI_REGEXP = /[一-龯]/;
const TEXTO_ALFANUMERICOS =/^[a-zA-Z0-9 ]*$/;
const SOLO_TEXTO = /^([a-zA-Z 'áéíóúÁÉÍÓÚñÑ])*$/;

export function isEmpty(value: any): boolean {
  return isNullOrUndefined(value) ||
    (typeof value === 'string' && value === '');
}

export class CustomValidators {
  public static documentNumber(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return DOCUMENT.test(control.value) ? null : { documentNumber: true };
  }
  public static noNumerosNegativos(control: AbstractControl): ValidationErrors | null {
    if (control.value == null || control.value === '') {
      return null; // Permitir campos vacíos
    }

    const inputString = control.value.toString();
    const isNegative = /^-/.test(inputString);

    if (isNegative || SOLO_NUMEROS_NO_NEGATIVOS.test(inputString)) {
      return { noNumerosNegativos: true };
    }

    return null;
  }

  public static dominio(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return (PATENTE_ARG.test(control.value)) ? null : { dominio: true };
  }


  public static decimal_positivo_coma(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return DECIMAL_POSITIVO_CON_COMA.test(control.value) ? null : { decimal_positivo_coma: true };
  }

  public static texto_alfanumerico(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null; // Retorna nulo si el campo está vacío
    }

    return TEXTO_ALFANUMERICOS.test(control.value) ? null : { texto_alfanumerico: true };
  }
  public static cuilNumber(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return CUIL.test(control.value) ? null : { cuilNumber: true };
  }

  public static areaCode(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return (control.value > 9) ? null : { codArea: true };
  }

  public static phoneNumber(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return (control.value > 9) ? null : { phoneNumber: true };
  }

  public static number(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return NUMBER_REGEXP.test(control.value) ? null : { number: true };
  }

  public static decimalNumber(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return DECIMAL_REGEXP.test(control.value) ? null : { decimal_positivo_punto: true };
  }

  public static decimalNumberWithTwoDigits(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return DECIMAL_WITH_TWO_DIGITS_REGEXP.test(control.value) ? null : { twoDecimal: true };
  }

  public static email(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return EMAIL_REGEXP.test(control.value) ? null : { email: true };
  }

  public static validText(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return (control.value.toString().replace(TEXT_REGEXP, '').length === 0) ? null : { validText: true };
  }

  public static soloTexto(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return (control.value.toString().replace(SOLO_TEXTO, '').length === 0) ? null : { validText: true };
  }

  public static validWhitespace(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return (control.value.toString().replace(WHITESPACE, '').length !== 0) ? null : { validWhitespace: true };
  }

  public static validPassword(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    const retu = PASSWORD_REGEXP.test(control.value) ? null : { validPassword: true };
    return retu;
  }

  public static validTextAndNumbers(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return (control.value.toString().replace(TEXT_NUMBER_REGEXP, '').length === 0) ? null : { validTextAndNumbers: true };
  }

  public static fileName(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return FILENAME_REGEXP.test(control.value) ? null : { fileName: true };
  }

  public static minValue(minValue: number): ValidatorFn {
    return (control) => {
      const number = CustomValidators.number(control);

      if (!isNullOrUndefined(number)) {
        return number;
      }

      if (isEmpty(control.value)) {
        return null;
      }

      const value = parseInt(control.value, 10);

      return isNaN(value) || (value < minValue) ?
        { minValue: { requiredValue: minValue, actualValue: control.value } } :
        null;
    };
  }

  public static maxValue(maxValue: number) {
    return (control) => {
      const number = CustomValidators.number(control);

      if (!isNullOrUndefined(number)) {
        return number;
      }

      if (isEmpty(control.value)) {
        return null;
      }

      const value = parseInt(control.value, 10);

      return isNaN(value) || (value > maxValue) ?
        { maxValue: { requiredValue: maxValue, actualValue: control.value } } :
        null;
    };
  }

  public static minDecimalValue(minValue: number): ValidatorFn {
    return (control) => {
      const number = CustomValidators.decimalNumber(control);

      if (!isNullOrUndefined(number)) {
        return number;
      }

      if (isEmpty(control.value)) {
        return null;
      }

      const value = parseFloat(control.value);

      return isNaN(value) || (value < minValue) ?
        { minValue: { requiredValue: minValue, actualValue: control.value } } :
        null;
    };
  }

  public static maxDecimalValue(maxValue: number) {
    return (control) => {
      const number = CustomValidators.decimalNumber(control);

      if (!isNullOrUndefined(number)) {
        return number;
      }

      if (isEmpty(control.value)) {
        return null;
      }

      const value = parseFloat(control.value);

      return isNaN(value) || (value > maxValue) ?
        { maxValue: { requiredValue: maxValue, actualValue: control.value } } :
        null;
    };
  }

  public static maxDecimalValueConComa(maxValue: number) {
    return (control) => {
      const number = CustomValidators.decimal_positivo_coma(control);

      if (!isNullOrUndefined(number)) {
        return number;
      }

      if (isEmpty(control.value)) {
        return null;
      }

      const value = parseFloat(control.value);

      return isNaN(value) || (value > maxValue) ?
        { maxValue: { requiredValue: "999,999", actualValue: control.value } } :
        null;
    };
  }

  public static diffTo(valor: any): ValidatorFn {
    return (control) => {
      if (isEmpty(control.value)) {
        return null;
      }

      let actualValue = control.value;

      if (!isNaN(valor)) {
        actualValue = parseFloat(actualValue);
      }

      return (actualValue !== valor) ?
        null :
        { diffTo: { requiredValue: valor, actualValue: control.value } };
    };
  }

  public static digitNumber(digitos: number): ValidatorFn {
    return (control) => {
      const number = CustomValidators.number(control);

      if (!isNullOrUndefined(number)) {
        return number;
      }

      if (isEmpty(control.value)) {
        return null;
      }

      const valorActual = control.value;
      return (valorActual.toString().length === digitos) ?
        null :
        { digitNumber: { requiredValue: digitos, actualValue: control.value } };
    };
  }

  public static soloUnCampo(controlsToVerify?: string[]): ValidatorFn {
    return (group) => {
      let hasValue = 0;
      if (group && group instanceof FormGroup && group.controls) {
        for (const control in controlsToVerify) {
          if (group.controls.hasOwnProperty(control) && group.controls[control].valid &&
            group.controls[control].value) {
            hasValue++;
          }
        }
      }
      return hasValue <= 1 ? null : { oneInputOnly: { requiredValue: true, actualValue: hasValue } };
    };
  }

  public static noKanji(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }

    return KANJI_REGEXP.test(control.value) ? { textoValido: true } : null;
  }

  public static atLeastOneInput(group: AbstractControl, controlsToVerify?: string): ValidationErrors | null {
    let isAtLeastOne = false;
    if (group && group instanceof FormGroup && group.controls) {
      if (group.controls.hasOwnProperty(controlsToVerify) && group.controls[controlsToVerify].valid &&
        group.controls[controlsToVerify].value) {
        isAtLeastOne = true;
      }
    }
    return isAtLeastOne ? null : { atLeastOneInput: true };
  }

  public static atLeastOneItem(controlAVerificar?: string, nombreItem?: string): ValidatorFn {
    return (array) => {
      let atLeastOneItem = false;
      if (array && array instanceof FormArray && array.controls) {
        for (const group in array.controls) {
          const atLeastOneInput = CustomValidators.atLeastOneInput(array.controls[group], controlAVerificar);
          if (atLeastOneInput === null) {
            atLeastOneItem = true;
            break;
          }
        }
      }
      return atLeastOneItem ? null : {
        atLeastOneItem: {
          requiredValue: true,
          actualValue: atLeastOneItem,
          item: nombreItem
        }
      };
    };
  }

  public static haveFilters(group: FormGroup): ValidationErrors {
    let haveFilter = false;
    if (group && group instanceof FormGroup && group.controls &&
      Object.getOwnPropertyNames(group.controls).some((property) =>
        group.controls[property].value && group.controls[property].valid)) {
      haveFilter = true;
    }
    return haveFilter ? { haveFilters: true } : null;
  }

  static toDate(toDate?: Date): ValidatorFn {
    return (control) => {
      if (isEmpty(control.value) || isEmpty(toDate)) {
        return null;
      }
      toDate.setHours(0, 0, 0, 0);

      return (toDate < control.value) ?
        {
          toDate: {
            requiredValue: toDate.toLocaleDateString(),
            actualValue: control.value,
            textValue: 'Debe ser menor o igual a la fecha'
          }
        } : null;
    };
  }

  public static date(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return DATE_REGEXP.test(control.value) ? null : { date: true };
  }

  static minDate(date: Date): ValidatorFn {
    return (control) => {
      if (isEmpty(control.value)) {
        return null;
      }
      control.value.setHours(0, 0, 0, 0);

      const toDay = date;
      toDay.setHours(0, 0, 0, 0);

      return (control.value < toDay) ?
        {
          toDate: {
            requiredValue: toDay.toLocaleDateString(),
            actualValue: new Date(),
            textValue: 'Debe ser mayor o igual a la fecha'
          }
        } : null;
    };
  }

  static fromDate(fromDate?: Date): ValidatorFn {
    return (control) => {
      if (isEmpty(control.value) || isEmpty(fromDate)) {
        return null;
      }
      fromDate.setHours(0, 0, 0, 0);

      return (fromDate > control.value) ?
        {
          fromDate: {
            requiredValue: fromDate.toLocaleDateString(),
            actualValue: control.value,
            textValue: 'Debe ser mayor o igual a la fecha'
          }
        } : null;
    };
  }

  public static document(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    const number = CustomValidators.number(control);
    if (!isNullOrUndefined(number)) {
      return number;
    }
    const valorActual = control.value.toString();
    return (valorActual.length <= 8 && valorActual.length >= 6) ?
      null : { document: true };
  }

  public static cbu(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    const numberControl = CustomValidators.number(control);
    if (!isNullOrUndefined(numberControl)) {
      return numberControl;
    }

    if (control.value.toString().length !== 22) {
      return { digitNumber: { requiredValue: 22, actualValue: control.value } };
    }

    function valdiateBackCode(code) {
      if (code.length !== 8) {
        return false;
      }
      const bank = code.substr(0, 3);
      const firstCheckDigit = code[3];
      const branch = code.substr(4, 3);
      const secondCheckDigit = code[7];
      const sum = bank[0] * 7 + bank[1] * 1 + bank[2] * 3 + firstCheckDigit * 9 + branch[0] * 7 + branch[1] * 1 + branch[2] * 3;
      const difference = 10 - (sum % 10);
      if (+secondCheckDigit !== 0 && +difference === +secondCheckDigit) {
        return true;
      }
      if (+secondCheckDigit === 0 && +difference === 10) {
        return true;
      }
      return false;
    }

    function validateAccount(account) {
      if (account.length !== 14) {
        return false;
      }
      const checkDigit = account[13];
      const sum = account[0] * 3 + account[1] * 9 + account[2] * 7 + account[3] * 1 + account[4] * 3 + account[5] * 9 +
        account[6] * 7 + account[7] * 1 + account[8] * 3 + account[9] * 9 + account[10] * 7 + account[11] * 1 + account[12] * 3;
      const difference = 10 - (sum % 10);
      if (+checkDigit !== 0 && +difference === +checkDigit) {
        return true;
      }
      if (+checkDigit === 0 && +difference === 10) {
        return true;
      }
      return false;
    }

    return (valdiateBackCode(control.value.substr(0, 8)) &&
      validateAccount(control.value.substr(8, 14))) ? null :
      { cbu: true };
  }

  public static cuilUnique(value: any): ValidatorFn | null {
    return (control) => {
      if (isEmpty(control.value)) {
        return null;
      }
      return (value.findIndex((x) => x.cuil === control.value) === -1) ?
        null : { cuilUnique: true };
    };
  }

  public static percentage(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return PERCENTAGE.test(control.value) ? null : { percentage: true };
  }

  public static accordance(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return (control.value) ? null : { accordance: true };
  }

  public static customRequired(control: AbstractControl): ValidationErrors | null {
    if (control.touched) {
      if (isEmpty(control.value)) {
        return { customRequired: true };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  public static numberPhone(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return NUMBER_PHONE_REGEXP.test(control.value) ? null : { numberPhone: true };
  }

  public static nomenclaturaCatastral(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return NOMENCLATURA_CATASTRO.test(control.value) ? null : { nomenclaturaCatastral: true };
  }

  public static unidadCatastral(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return UNIDAD_CATASTRO.test(control.value) ? null : { unidadCatastral: true };
  }

  public static textoValido(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return TEXTO_VALIDO.test(control.value) ? null : { textoValido: true };
  }

  public static soloNumeros(control: AbstractControl): ValidationErrors | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return SOLO_NUMEROS.test(control.value) ? null : { soloNumeros: true };
  }

  public static estaEnLista(lista: any[]): ValidatorFn {
    return (control) => {
      const index = lista.findIndex((x) => x.nombre == control.value);

      if (isEmpty(control.value)) {
        return null;
      }

      return (index >= 0) ? null : { estaEnLista: true };
    };
  }
}

export class ErrorMessages {
  public static messageOf(validatorName: string, validatorValue?: any): string {
    const config = {
      areaCode: 'Debe ingresar un código válido.',
      numberPhone: 'Debe ingresar un número válido.',
      validText: 'Debe ingresar un texto válido.',
      validWhitespace: 'Debe ingresar un texto válido.',
      validTextAndNumbers: 'Debe ingresar un texto alfanumérico.',
      required: 'El campo es requerido.',
      number: 'Ingresar sólo números.',
      email: 'El email es inválido.',
      maxlength: `No superar los ${ validatorValue.requiredLength } caracteres.`,
      minlength: `Ingresar al menos ${ validatorValue.requiredLength } caracteres.`,
      minValue: `El valor mínimo es ${ validatorValue.requiredValue }.`,
      maxValue: `El valor máximo es ${ validatorValue.requiredValue }`,
      minDate: `La fecha mínima es ${ validatorValue.requiredValue }.`,
      maxDate: `La fecha máxima es ${ validatorValue.requiredValue }.`,
      minDecimalValue: `El valor mínimo es ${ validatorValue.requiredValue }.`,
      digitNumber: `La cantidad de digitos requerida es ${ validatorValue.requiredValue }.`,
      date: 'Ingresar sólo fechas.',
      atLeastOneInput: 'Al menos un campo es requerido.',
      atLeastOneItem: `Al menos un ${ validatorValue.item } es requerido y debe ser marcado como entregado.`,
      oneInputOnly: 'Solo un campo es permitido.',
      diffTo: `El valor debe ser distinto a ${ validatorValue.requiredValue }.`,
      twoDecimal: 'Solo se admiten números positivos y con dos decimales.',
      fileName: 'Solo se admiten letras, guiones y números.',
      validPassword: 'Debe ingresar una contraseña válida.',
      toDate: `${ validatorValue.textValue }: ${ validatorValue.requiredValue }.`,
      fromDate: `${ validatorValue.textValue }: ${ validatorValue.requiredValue }.`,
      document: 'Ingresar un documento válido.',
      notUniqueName: 'Este nombre ya se encuentra registrado. Ingrese uno diferente.',
      affair: `${ validatorValue.textValue }`,
      cuilNumber: 'Debe ingresar un cuil válido.',
      documentNumber: 'Debe ingresar un documento válido.',
      cuilUnique: 'EL solicitante ya se encuentra agregado.',
      dominio: 'Debe ingresar un dominio válido.',
      percentage: 'Debe ingresar un porcentaje válido.',
      cbu: 'Debe ingresar un CBU válido.',
      accordance: 'Debe prestar conformidad.',
      decimal_positivo_coma: 'Por favor ingrese un número decimal con coma',
      decimal_positivo_punto: 'Este campo sólo permite el ingreso de números decimales con punto.',
      estaEnLista: 'Por favor, ingrese valor válido.',
      textoValido: 'Ingrese caracteres válidos',
      soloNumeros: 'Debe ingresar números',
      noNumerosNegativos:'No se pueden ingresar numeros negativo',
      texto_alfanumerico:'Ingrese un texto válido'
    };
    return config[validatorName];
  }
}

export function alMenosUnCheckboxTrue(controlsName: string[]): ValidatorFn | null {
  return (formGroup: FormGroup) => {
    let checked = 0;
    const minRequired = 1;
    controlsName.forEach((x) => {
      const control = formGroup.controls[x];
      if (control.value === true) {
        checked++;
      }
    });

    if (checked < minRequired) {
      return {
        requireCheckboxToBeChecked: true
      };
    }

    return null;
  };
}
