<div class="container-fluid mt-3 mb-5">
  <mat-card>
    <form [formGroup] = formPersona>
      <mat-card-title class="titulo-card">
        Registro de personas en el sistema de administración municipal
      </mat-card-title>

      <mat-card-content>
        <mat-card-header class="header-card">
          <h5>Datos personales</h5>
        </mat-card-header>

        <mat-card>
          <mat-card-content>
            <div class="row">
              <div class="col-md-4">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>Apellido y nombre</mat-label>
                  <input
                    #inputApellidoNombre
                    matInput
                    [formControl]="formPersona.controls.apellidoNombre"
                    maxlength="100"
                  />
                  <mat-hint align="end">{{inputApellidoNombre.value?.length || 0}}/100</mat-hint>
                </mat-form-field>
                <dca-control-messages [control]="formPersona.controls.apellidoNombre"></dca-control-messages>
              </div>

              <div class="col-md-2">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>Género</mat-label>
                  <mat-select
                    [formControl]="formPersona.controls.genero">
                    <mat-option
                      *ngFor="let genero of this.generos"
                      [value]="genero.id"
                    >
                      {{genero.descripcion}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <dca-control-messages [control]="formPersona.controls.genero"></dca-control-messages>
              </div>

              <div class="col-md-2">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>Tipo documento</mat-label>
                  <mat-select
                    [formControl]="formPersona.controls.tipoDocumento">
                    <mat-option
                      *ngFor="let tipoDocumento of this.tiposDocumentos"
                      [value]="tipoDocumento.id"
                    >
                      {{tipoDocumento.descripcion}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <dca-control-messages [control]="formPersona.controls.tipoDocumento"></dca-control-messages>
              </div>

              <div class="col-md-2">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>Nro documento</mat-label>
                  <input
                    #inputNroDocumento
                    type="number"
                    min="0"
                    matInput
                    [formControl]="formPersona.controls.numeroDocumento"
                  />
                  <mat-hint align="end">{{inputNroDocumento.value?.length || 0}}/8</mat-hint>
                </mat-form-field>
                <dca-control-messages [control]="formPersona.controls.numeroDocumento"></dca-control-messages>
              </div>

              <div class="col-md-2">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>CUIL</mat-label>
                  <input
                    #inputCuil
                    type="number"
                    min="0"
                    matInput
                    [formControl]="formPersona.controls.cuil"
                  />
                  <mat-hint align="end">{{inputCuil.value?.length || 0}}/11</mat-hint>
                </mat-form-field>
                <dca-control-messages [control]="formPersona.controls.cuil"></dca-control-messages>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>Fecha nacimiento</mat-label>
                  <input
                    matInput
                    readonly
                    [matDatepicker]="pickerFechaNacimiento"
                    [formControl]="formPersona.controls.fechaNacimiento"/>

                  <mat-datepicker-toggle
                    *ngIf="formPersona.controls.fechaNacimiento != null"
                    matSuffix
                    (click)="formPersona.controls.fechaNacimiento.setValue('')"
                  >
                    <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker-toggle matSuffix [for]="pickerFechaNacimiento">
                    <mat-icon matDatepickerToggleIcon>
                      <span class="material-icons arrowDown"> today </span>
                    </mat-icon>
                  </mat-datepicker-toggle>

                  <mat-datepicker #pickerFechaNacimiento></mat-datepicker>
                </mat-form-field>
                <dca-control-messages [control]="formPersona.controls.fechaNacimiento"></dca-control-messages>
              </div>

              <div class="col-md-2">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>Estado civil</mat-label>
                  <mat-select
                    [formControl]="formPersona.controls.estadoCivil">
                    <mat-option
                      *ngFor="let estadoCivil of this.estadosCiviles"
                      [value]="estadoCivil.id"
                    >
                      {{estadoCivil.descripcion}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <dca-control-messages [control]="formPersona.controls.estadoCivil"></dca-control-messages>
              </div>

              <div class="col-md-2">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>Teléfono celular</mat-label>
                  <input
                    #inputTelefonoCelular
                    type="number"
                    matInput
                    [formControl]="formPersona.controls.telefono"
                  />
                  <mat-hint align="end">{{inputTelefonoCelular.value?.length || 0}}/13</mat-hint>
                </mat-form-field>
                <dca-control-messages [control]="formPersona.controls.telefono"></dca-control-messages>
              </div>

              <div class="col-md-4">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>Email</mat-label>
                  <input
                    #inputEmail
                    matInput
                    [formControl]="formPersona.controls.email"
                    maxlength="150"
                  />
                  <mat-hint align="end">{{inputEmail.value?.length || 0}}/150</mat-hint>
                </mat-form-field>
                <dca-control-messages [control]="formPersona.controls.email"></dca-control-messages>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <br/>

        <mat-card-header class="header-card">
          <h5>Domicilio</h5>
        </mat-card-header>
        <mat-card>
          <mat-card-content>
            <div class="row">
              <div class="col-md-4">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>País</mat-label>
                  <mat-select
                    [formControl]="formPersona.controls.pais">
                    <mat-option
                      *ngFor="let pais of this.paises"
                      [value]="pais.id"
                    >
                      {{pais.descripcion}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <dca-control-messages [control]="formPersona.controls.pais"></dca-control-messages>
              </div>

              <div class="col-md-4">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>Provincia</mat-label>
                  <mat-select
                    [formControl]="formPersona.controls.provincia">
                    <mat-option
                      *ngFor="let provincia of this.provincias"
                      [value]="provincia.id"
                    >
                      {{provincia.descripcion}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <dca-control-messages [control]="formPersona.controls.provincia"></dca-control-messages>
              </div>

              <div class="col-md-4">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>Localidad</mat-label>
                  <mat-select
                    [formControl]="formPersona.controls.localidad">
                    <mat-option
                      *ngFor="let localidad of this.localidades"
                      [value]="localidad.id"
                    >
                      {{localidad.descripcion}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <dca-control-messages [control]="formPersona.controls.localidad"></dca-control-messages>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>Barrio</mat-label>
                  <input matInput
                         [formControl]="formPersona.controls.barrio"
                         [matAutocomplete]="autoCompleteBarrio">
                </mat-form-field>
                <dca-control-messages [control]="formPersona.controls.barrio"></dca-control-messages>
                <mat-autocomplete #autoCompleteBarrio="matAutocomplete">
                  <mat-option *ngFor="let barrio of this.barriosFiltrados" [value]="barrio.nombre">
                    {{barrio.nombre}}
                  </mat-option>
                </mat-autocomplete>
              </div>

              <div class="col-md-3">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>Calle</mat-label>
                  <input matInput
                         [formControl]="formPersona.controls.calle"
                         [matAutocomplete]="autoCompleteCalle">
                </mat-form-field>
                <dca-control-messages [control]="formPersona.controls.calle"></dca-control-messages>
                <mat-autocomplete #autoCompleteCalle="matAutocomplete">
                  <mat-option *ngFor="let calle of this.callesFiltradas" [value]="calle.nombre">
                    {{calle.nombre}}
                  </mat-option>
                </mat-autocomplete>
              </div>

              <div class="col-md-2">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>Número</mat-label>
                  <input matInput
                         type="number"
                         min="0"
                         [formControl]="formPersona.controls.numero">
                </mat-form-field>
              </div>

              <div class="col-md-2">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>Piso</mat-label>
                  <input matInput
                         [formControl]="formPersona.controls.piso">
                </mat-form-field>
              </div>

              <div class="col-md-2">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>Departamento</mat-label>
                  <input matInput
                         [formControl]="formPersona.controls.departamento">
                </mat-form-field>
              </div>
            </div>

          </mat-card-content>
        </mat-card>

        <br/>

        <mat-card-header class="header-card">
          <h5>Situación contribuyente</h5>
        </mat-card-header>
        <mat-card>
          <mat-card-content>
            <div class="row">
              <div class="col-md-4">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>Condición IVA</mat-label>
                  <mat-select
                    [formControl]="formPersona.controls.condicionIVA">
                    <mat-option
                      *ngFor="let condicionIVA of this.condicionesIVA"
                      [value]="condicionIVA.id"
                    >
                      {{condicionIVA.descripcion}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <dca-control-messages [control]="formPersona.controls.condicionIVA"></dca-control-messages>
              </div>

              <div class="col-md-4">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>Estado IIBB</mat-label>
                  <mat-select
                    [formControl]="formPersona.controls.estadoIIBB">
                    <mat-option
                      *ngFor="let estadoIIBB of this.estadosIIBB"
                      [value]="estadoIIBB.id"
                    >
                      {{estadoIIBB.descripcion}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <dca-control-messages [control]="formPersona.controls.estadoIIBB"></dca-control-messages>
              </div>

              <div class="col-md-4">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label>Clasificación IIBB</mat-label>
                  <mat-select
                    [formControl]="formPersona.controls.clasificacionIIBB">
                    <mat-option
                      *ngFor="let clasificacionIIBB of this.clasificacionesIIBB"
                      [value]="clasificacionIIBB.id"
                    >
                      {{clasificacionIIBB.descripcion}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <dca-control-messages [control]="formPersona.controls.clasificacionIIBB"></dca-control-messages>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-card-content>

      <br/>

      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-outline-secondary btn-margin float-right" (click)="cancelar()">
            Cancelar
          </button>

          <button class="btn btn-success btn-margin float-right" (click)="guardar()">
            Guardar
          </button>
        </div>
      </div>
    </form>
  </mat-card>
</div>
