import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenLocalStorage } from '../../services/token-storage.service';
import { auth } from '../auth.constants';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { mergeMap } from 'rxjs/operators';
import * as moment from 'moment';
import { Sesion } from '../../models/sesion.model';
import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class CredentialsInterceptor implements HttpInterceptor {
  public static keepChecking = true;
  public static updatingToken = false;
  session: Sesion;

  constructor(
    private tokenLocalStorage: TokenLocalStorage,
    private storage: StorageMap,
    private notificationService: NotificationService,
    private http: HttpClient,
    private authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const search = window.location.search;
    const tokenVedi = localStorage.getItem('token');
    if (req.url.endsWith(auth.URL_AUTH) || req.url.endsWith(auth.URL_REFRESH_TOKEN)) {
      return next.handle(req);
    } else {
      CredentialsInterceptor.keepChecking = true;
      if (CredentialsInterceptor.keepChecking && !search.startsWith('?') && tokenVedi) {
        const currentToken = this.authService.decodeToken(tokenVedi);
        if (moment(currentToken.expirationDate).isBefore(moment(new Date()))) {
          CredentialsInterceptor.keepChecking = false;
          return (
            this.notificationService
              .confirmation(
                'Su token de sesión ha expirado. ¿Desea renovarlo?',
                'Sesión expirada'
              )
              .afterClosed()
              .pipe(mergeMap((value: boolean) => {
                if (!value) {
                  localStorage.removeItem('auth_dca_user');
                  localStorage.removeItem('dca_token');
                  localStorage.removeItem('refreshToken');
                  localStorage.removeItem('token');
                  window.location.reload();
                } else {
                  CredentialsInterceptor.updatingToken = false;
                  const newHttpOptions = {
                    headers: new HttpHeaders({
                      ['Content-Type']: auth.CONTENT_TYPE_DEFAULT
                    }),
                    withCredentials: this.tokenLocalStorage.get() ? true : false
                  };

                  const body = {
                    token: localStorage.getItem('refreshToken')
                  };

                  this.http.post(`security/refreshToken`, body).subscribe((res: any) => {
                    localStorage.setItem('token', res.token);
                    localStorage.setItem('refreshToken', res.refreshToken);
                    this.session = this.authService.getSession();
                    this.session.user.userType = this.authService.getUserType();
                    localStorage.setItem('auth_dca_user', this.authService.encrypt(JSON.stringify(this.session.user.userType)));
                    this.authService.permissions(+this.session.user.id, this.session.user.userType && this.session.user.userType.id ? this.session.user.userType.id : this.session.userType[0]?.id).subscribe((x) => {
                      this.storage.set(this.session.user.id.toString(), x ? x.toString() : '').subscribe((y) => {});
                      window.location.reload();
                    });
                  }, (error) => {
                    window.location.href= environment.vediURl;
                  });

                  req = req.clone(newHttpOptions);
                  return next.handle(req);
                }
              })
              ));
        }
      }
    }

    const httpOptions = {
      headers: new HttpHeaders({
        ['Content-Type']: auth.CONTENT_TYPE_DEFAULT,
        ['authorization']: this.tokenLocalStorage.get() ? this.tokenLocalStorage.get().token : '',
        ['tokenVeDi']: localStorage.getItem('token') ? localStorage.getItem('token') : '',
        ['refreshTokenVeDi']: localStorage.getItem('refreshToken') ? localStorage.getItem('refreshToken') : ''
      }),
      withCredentials: this.tokenLocalStorage.get() ? true : false
    };

    req = req.clone(httpOptions);
    return next.handle(req);
  }
}
