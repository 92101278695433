export const auth = {
  URL_AUTH: 'login',
  URL_REFRESH_TOKEN: 'refreshToken',
  URL_MENU: 'menu',
  URL_PERMISSION: 'permissions',
  URL_FORMACION: 'formacion',
  URL_DOCUMENT: 'registrar-documentacion',
  URL_LOGOUT: 'logout',
  HEADER_NAME_AUTH: 'authorization',
  CONTENT_TYPE_DEFAULT: 'application/json',
  ENCRYPT_PASS: 'a2\'vCK_Gu8fN@h!R'
};
