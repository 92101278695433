<div class="row">
  <div class="col-md-12 titulo-perfil">Seleccione el perfil</div>

  <div class="col-md-12" *ngFor="let perfil of data.perfiles">
    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="perfilSeleccionado">
      <mat-radio-button class="example-radio-button" [value]="perfil.id">
        <strong>{{perfil.nombre}}</strong>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <br>

  <div class="col-md-12">
    <button class="btn btn-primary float-right" (click)="onNoClick()">
      Aceptar
    </button>
    <button class="btn btn-outline-secondary btn-margin float-right" (click)="close()">
      Cancelar
    </button>
  </div>
</div>
