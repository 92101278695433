import { NgModule } from '@angular/core';
import { ParserTokenService } from '../services/parser-token-service';
import { JwtModule } from '@auth0/angular-jwt';
import { TokenLocalStorage } from '../services/token-storage.service';
import { TokenService } from '../services/token.service';
import { AuthService } from '../services/auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiUrlInterceptor } from './interceptors/api-url-base.interceptor';
import { CredentialsInterceptor } from './interceptors/credentials.interceptor';
import { TimeOutInterceptor } from './interceptors/timeout.interceptor';
import { StatusResponseInterceptor } from './interceptors/status-response.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { SpinnerInterceptor } from './interceptors/spinner.interceptor';
import { AuthGuard } from '../shared/guards/auth.guard';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { EncryptionInteceptor } from './interceptors/encryption.interceptor';

export function tokenGetter(): string {
  return localStorage.getItem(TokenLocalStorage.key);
}

@NgModule({
  imports: [
    FontAwesomeModule,
    MaterialFileInputModule,
    JwtModule.forRoot({
      config: { tokenGetter }
    })
  ],
  exports: [],
  declarations: [],
  entryComponents: [],
  providers: [
    ParserTokenService,
    TokenLocalStorage,
    TokenService,
    AuthService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: CredentialsInterceptor
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: TimeOutInterceptor
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: ApiUrlInterceptor
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: AuthInterceptor
    },
/*     {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: EncryptionInteceptor
    }, */
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: SpinnerInterceptor
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: StatusResponseInterceptor
    }
  ]
})
export class CoreModule {}
