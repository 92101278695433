import { Injectable } from '@angular/core';
import { Token } from '../models/token.model';
import { ParserTokenService } from './parser-token-service';

@Injectable({
  providedIn: 'root'
})
export class TokenLocalStorage {
  public static key = 'dca_token';
  private localStorage;

  constructor(private parser: ParserTokenService) {
    this.localStorage = localStorage;
  }

  get(): Token {
    return this.parser.unwrap(this.localStorage.getItem(TokenLocalStorage.key));
  }

  set(token: Token): void {
    const raw = this.parser.wrap(token);
    this.localStorage.setItem(TokenLocalStorage.key, raw);
  }

  /**
   * Clears token from localStorage
   */
  clear(): void {
    this.localStorage.removeItem(TokenLocalStorage.key);
  }
}
