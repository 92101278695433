import { NgModule } from '@angular/core';
import { RegistroPersonasComponent } from './registro-personas/registro-personas.component';
import {RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../../shared/shared.module';
import {AuthGuard} from '../../../shared/guards/auth.guard';

const routes: Routes = [
  { path: 'registro-personas', component: RegistroPersonasComponent, canActivate: [ AuthGuard ] }
];

@NgModule({
  imports: [ RouterModule.forChild(routes), CommonModule, SharedModule ],
  declarations: [
    RegistroPersonasComponent
  ],
  exports: [ RouterModule ],
  providers: [AuthGuard]
})

export class SamModule {}
