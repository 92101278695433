import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Sesion } from 'src/app/models/sesion.model';
import { AuthService } from 'src/app/services/auth.service';
import { TokenLocalStorage } from 'src/app/services/token-storage.service';
import { SnackBarService } from '../../../services/snack-bar.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'dca-layout',
  templateUrl: './layout.component.html',
  styleUrls: [ './layout.component.scss' ]
})
export class LayoutComponent implements OnInit {
  public sideBarOpen: boolean;
  constructor(
    private router: Router,
    private authService: AuthService,
    private tokenLocalStorage: TokenLocalStorage,
    private storage: StorageMap,
    private snackBarService: SnackBarService,
    private dialog: MatDialog
  ) {}
  session: Sesion;

  ngOnInit() {
    this.session = this.authService.getSession();
    this.authService.verificarLoginVeDi().subscribe((res) => {
      if (res) {
        if (res === 'OK') {
          this.authService.setCurrentSession();
          this.session = this.authService.getSession();
        } else {
          this.router.navigate([ '' ]);
          this.snackBarService.openSnackBarWarning('Para realizar acciones en el sistema, debe loguearse en VeDi');
        }
      }
    });

    if (this.session?.user) {
      const auth = localStorage.getItem('dca_token');
      if (!auth) {
        this.router.navigate([ 'login' ]);
      }
    }

    this.setSubscribers();
  }

  sideBarChange(event: any): void {
    this.sideBarOpen = event;
  }

  sideBarToggler(event: any) {
    if (event) {
      this.sideBarOpen = !this.sideBarOpen;
    } else {
      this.sideBarOpen = event;
    }
  }
  private setSubscribers(): void {
    this.authService.perfilChanged.subscribe((s) => {
      if (s) {
        this.session = s;
        this.storage.get(this.session.user.id.toString()).subscribe((user) => {
          if (this.session && this.session.userType && this.session.userType.length > 1 && !user) {
            this.authService.setCurrentNavBar(false);
            this.router.navigate([ 'login' ]);
          }
        });
        this.authService.setCurrentNavBar(true);
        this.authService.permissions(+this.session.user.id, this.session.user.userType && this.session.user.userType.id ? this.session.user.userType.id : this.session.userType[0]?.id).subscribe((x) => {
          this.storage.clear().subscribe((y) => {});
          this.storage.set(this.session.user.id.toString(), x ? x.toString() : '').subscribe((y) => {});
        });
        this.authService.getMenu(+this.session.user.id, this.session.user.userType && this.session.user.userType.id ? this.session.user.userType.id : this.session.userType[0]?.id).subscribe((menu) => {
          this.authService.menuChanged.next(menu);
        });
      } else {
        this.authService.setCurrentNavBar(false);
      }
    });
  }

  onActivate(event) {
    window.scroll(0, 0);
  }
}
