import { Injectable } from '@angular/core';
import { auth } from '../core/auth.constants';
import * as CryptoJS from 'crypto-js';

const password = auth.ENCRYPT_PASS;

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  encriptar(textoPlano) {
    const iv = CryptoJS.enc.Base64.parse('');
    const key= CryptoJS.SHA256(password);
    const data= textoPlano;
    const encryptedString = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return encryptedString.toString();
  }
}
