import { NgModule } from '@angular/core';
import { StatusControlDirective } from './status-control.directive';
import { DdMatExpansionFeedbackDirective } from './dd-mat-expansion-feedback.directive';
import { RerenderDirective } from './re-render.directive';

@NgModule({
  imports: [],
  exports: [StatusControlDirective, DdMatExpansionFeedbackDirective, RerenderDirective],
  declarations: [StatusControlDirective, DdMatExpansionFeedbackDirective, RerenderDirective],
  entryComponents: [],
  providers: []
})
export class DirectivesModule {}
