import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TipoTramiteModel } from '../models/tipo-tramite.model';
import {BarrioModel} from '../models/barrio.model';
import {CalleModel} from '../models/calle.model';
import {PerfilModel} from '../models/Perfil.model';
import {PasoModel} from "../models/paso.model";
import {UsuarioPerfiles} from "../models/usuario-perfiles.model";
import {Usuario} from "../models/usuario.model";
import {CustomResponseModel} from "../models/custom-response.model";

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  private _controllerName = 'security';

  constructor(private http: HttpClient) { }

  public getTiposTramites(): Observable<TipoTramiteModel[]> {
    return this.http.get<TipoTramiteModel[]>(`${this._controllerName}/getTiposTramites`);
  }

  public getBarriosSam(): Observable<BarrioModel[]> {
    return this.http.get<BarrioModel[]>(`${this._controllerName}/getBarriosSam`);
  }

  public getCallesSam(): Observable<CalleModel[]> {
    return this.http.get<CalleModel[]>(`${this._controllerName}/getCallesSam`);
  }

  public getPerfiles(): Observable<PerfilModel[]> {
    return this.http.get<PerfilModel[]>(`${this._controllerName}/getTypes`);
  }

  public obtenerPerfilesUsuario(idUsuario, apellidoNombre, cuil, idPerfil): Observable<UsuarioPerfiles[]> {
    const body = {
      idUsuario,
      apellidoNombre,
      cuil,
      idPerfil
    };
    return this.http.post<UsuarioPerfiles[]>(`${this._controllerName}/obtenerPerfilesUsuario`, body);
  }

  public obtenerUsuarioVedi(cuil): Observable<any> {
    const body = {
      cuil
    };
    return this.http.post<any>(`${this._controllerName}/obtenerUsuarioVedi`, body);
  }

  public altaUsuarioPerfiles(cuil, perfiles, apellido, nombre): Observable<CustomResponseModel> {
    const body = {
      cuil,
      perfiles,
      apellido,
      nombre
    };
    return this.http.post<any>(`${this._controllerName}/altaUsuarioPerfiles`, body);
  }
}
