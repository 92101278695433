import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from } from 'rxjs';
import { CredentialsInterceptor } from './credentials.interceptor';
import { auth } from '../auth.constants';

@Injectable({ providedIn: 'root' })
export class TimeOutInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (CredentialsInterceptor.updatingToken && !req.url.endsWith(auth.URL_AUTH)) {
      return from(this.handle(req, next));
    } else {
      return next.handle(req);
    }
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    do {
      await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 sec
    } while (CredentialsInterceptor.updatingToken);

    return next.handle(req).toPromise();
  }
}
