import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Observable } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Sesion } from 'src/app/models/sesion.model';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { FuncionalidadModel } from '../../models/funcionalidad.model';

@Injectable()
export class AuthGuard implements CanActivate {
  session: Sesion;
  BETWEEN_SLASHES = '^\/[^\/]+\/?([^\/]+)\/?';
  constructor(
    private authService: AuthService,
    private router: Router, private storage: StorageMap,
    private snackBarService: SnackBarService
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.session = this.authService.getSession();
    if (this.session) {
      this.storage.get(this.session.user.id.toString()).subscribe((permisos: string) => {
        const funcionalidades: FuncionalidadModel[] = this.authService.decrypt(permisos);
        if (this.router.routerState.snapshot.url !== '/' && funcionalidades.length) {
          let tienePermisoUrl = false;
          funcionalidades.forEach((funcionalidad) => {
            let rutaSinParametros = this.router.routerState.snapshot.url;
            let funcionalidadSinParametros = funcionalidad.url;

            if (funcionalidad.url.includes(':id')) {
              funcionalidadSinParametros = funcionalidadSinParametros.substring(0, funcionalidadSinParametros.lastIndexOf('/'));
              rutaSinParametros = rutaSinParametros.substring(0, rutaSinParametros.lastIndexOf('/'));
            }

            if (funcionalidadSinParametros === rutaSinParametros) {
              tienePermisoUrl = true;
            }
          });
          if (!tienePermisoUrl) {
            this.router.navigate([ '/' ]);
            this.snackBarService.openSnackBarError('No tiene permisos para acceder al recurso solicitado.');
            return false;
          }
        }
      });
    }
    return true;
  }
}
