<div class="container-fluid header" id="myHeader">
  <div class="row">
    <div class="row col-3 col-lg-7 responsive-logo" style="justify-content: left">
      <img src="../../../../assets/img/logo_header.png" class="img1"
           (click)="navigate('/')" alt="logo blanco - Municipalidad de Córdoba">
    </div>
    <div class="col-12 col-lg-5 responsive-btn" style="margin: auto; text-align: end; display: flex" *ngIf="session && session.user?.userType !== undefined">
      <button class="btn-user col-10 nombre-completo" mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" style="text-align: end">
        <span>{{session.user?.tieneRepresentadoSeleccionado === null ||
        session.user?.tieneRepresentadoSeleccionado === 'N' ? session.user?.lastName +', '+ session.user?.firstName+ ' '+ session.user?.cuil  + ' [' + session.user?.userType.nombre + ']' :
          'En nombre de ' + session.user?.representado.denominacion + ' [' + session.user?.userType.nombre + ']' }}</span>
        <mat-icon>expand_more</mat-icon>
      </button>
      <button class="btn-user col-10 nombre-solo-cuil" mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <span>{{session.user?.tieneRepresentadoSeleccionado === null ||
        session.user?.tieneRepresentadoSeleccionado === 'N' ? session.user?.cuil  + ' [' + session.user?.userType.nombre + ']' :
          'En nombre de ' + session.user?.representado.cuilCuit + ' [' + session.user?.userType.nombre + ']' }}</span>
        <mat-icon>expand_more</mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item (click)="volverAVedi()">
          <mat-icon>arrow_back</mat-icon>
          <span>Volver a VeDi</span>
        </button>
        <button mat-menu-item (click)="cerrarSesion()">
          <mat-icon>logout</mat-icon>
          <span>Cerrar sesión</span>
        </button>
        <button mat-menu-item (click)="cambiarPerfil()" *ngIf="session?.userType && session?.userType.length > 1">
          <mat-icon>person</mat-icon>
          <span>Cambiar perfil</span>
        </button>
      </mat-menu>
      <button *ngIf="session && session.user?.userType !== undefined" class="btn btn-link btn-menu col-2"
              (click)="toggleSideBar()"><i class="fa fa-bars fa-2x"></i></button>
    </div>
  </div>
</div>
