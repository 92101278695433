import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from './directives/directives.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SnackBarService } from '../services/snack-bar.service';
import { CustomMatPaginator } from './paginator/paginator';
import { ControlMessagesComponent } from './custom-validators/control-messages.component';
import { ControlMessageComponent } from './custom-validators/control-message.component';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatTableExporterModule } from 'mat-table-exporter';
import { DialogsComponent } from './dialogs/dialogs.component';
import { FooterComponent } from '../core/layout/footer/footer.component';
import { HeaderComponent } from '../core/layout/header/header.component';
import { SidebarComponent } from '../core/layout/sidebar/sidebar.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MessagesComponent } from './messages/messages.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FocusInvalidService } from '../services/focus-invalid.service';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgStepperModule } from 'angular-ng-stepper';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { ErrorCardComponent } from './error-card/error-card.component';
import { InfoCardComponent } from './info-card/info-card.component';
import { DefaultToPipe } from './pipes/DefaultToPipe.pipe';
import { MatTreeModule } from '@angular/material/tree';
import { NgxEditorModule } from 'ngx-editor';
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SafeHtmlPipe } from './pipes/SafeHtmlPipe.pipe';
import { DatosSolicitanteComponent } from './componentes-form-tramites/datos-solicitante/datos-solicitante.component';
import { DatosTitularComponent } from './componentes-form-tramites/datos-titular/datos-titular.component';
import { DatosDomicilioComponent } from './componentes-form-tramites/datos-domicilio/datos-domicilio.component';
import { DatosEstablecimientoComponent } from './componentes-form-tramites/datos-establecimiento/datos-establecimiento.component';
import { DatosDireccionTecnicaComponent } from './componentes-form-tramites/datos-direccion-tecnica/datos-direccion-tecnica.component';
import { AdjuntarDocumentacionComponent } from './componentes-form-tramites/adjuntar-documentacion/adjuntar-documentacion.component';
import { CampoObservacionesComponent } from './componentes-form-tramites/campo-observaciones/campo-observaciones.component';
import { PagoTasaComponent } from './componentes-form-tramites/pago-tasa/pago-tasa.component';
import { AdvertenciaDialogComponent } from './dialogs/advertencia-dialog/advertencia-dialog.component';
import { PadronRmeComponent } from './componentes-form-padrones/padron-rme/padron-rme.component';
import { PadronRmaComponent } from './componentes-form-padrones/padron-rma/padron-rma.component';
import { PadronRmtsaComponent } from './componentes-form-padrones/padron-rmtsa/padron-rmtsa.component';
import { PadronDtComponent } from './componentes-form-padrones/padron-dt/padron-dt.component';
import { SolicitudDtComponent } from './componentes-form-solicitudes/solicitud-dt/solicitud-dt.component';
import { SolicitudRmaComponent } from './componentes-form-solicitudes/solicitud-rma/solicitud-rma.component';
import { SolicitudRmeComponent } from './componentes-form-solicitudes/solicitud-rme/solicitud-rme.component';
import { SolicitudRmtsaComponent } from './componentes-form-solicitudes/solicitud-rmtsa/solicitud-rmtsa.component';
import { NgxMaskModule } from 'ngx-mask';
import { InformacionDialogComponent } from './dialogs/informacion-dialog/informacion-dialog.component';
import { UsuarioSamDialogComponent } from './dialogs/usuario-sam-dialog/usuario-sam-dialog.component';
import { PerfilesDialogComponent } from './dialogs/perfiles-dialog/perfiles-dialog.component';
import { InfoTipoDocDialogComponent } from './dialogs/info-tipo-doc-dialog/info-tipo-doc-dialog.component';
import { RmeDialogComponent } from './dialogs/rme-dialog/rme-dialog.component';
import { InformacionHomeRmeDialogComponent } from './dialogs/informacion-home-rme-dialog/informacion-home-rme-dialog.component';
import { InformacionHomeRmtsaDialogComponent } from './dialogs/informacion-home-rmtsa-dialog/informacion-home-rmtsa-dialog.component';
import { DatosProfesionalesComponent } from './componentes-form-tramites/datos-profesionales/datos-profesionales.component';
import { InformacionHomeRmdtDialogComponent } from './dialogs/informacion-home-rmdt-dialog/informacion-home-rmdt-dialog.component';
import { MisTramitesDialogComponent } from './dialogs/mis-tramites-dialog/mis-tramites-dialog.component';
import { NuevoDtDialogComponent } from './dialogs/nuevo-dt-dialog/nuevo-dt-dialog.component';
import { ContentChatsDialogComponent } from './dialogs/content-chats-dialog/content-chats-dialog.component';
import { ChatTextComponent } from './chat-text/chat-text.component';
import { ErrorRmdtDialogComponent } from './dialogs/error-rmdt-dialog/error-rmdt-dialog.component';
import { TransferirTramiteFormComponent } from './transferir-tramite-form/transferir-tramite-form.component';
import { ObservacionesComponent } from './observaciones/observaciones.component';
import { AsignarTramiteDialogComponent } from './dialogs/asignar-tramite-dialog/asignar-tramite-dialog.component';
import { HistorialInspeccionesComponent } from './historialInspecciones/historial-inspecciones.component';
import { DomicilioCatastroComponent } from './componentes-form-tramites/domicilio-catastro/domicilio-catastro.component';
import { DomicilioCatastroDialogComponent } from './dialogs/domicilio-catastro-dialog/domicilio-catastro-dialog.component';
import { ModalInfoInspeccionesComponent } from './modal-info-inspecciones/modal-info-inspecciones.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    DirectivesModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatTableModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    NgxSpinnerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatTableExporterModule,
    NgxMatSelectSearchModule,
    CdkStepperModule,
    NgStepperModule,
    NgxMatSelectSearchModule,
    PdfJsViewerModule,
    MaterialFileInputModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    MaterialFileInputModule,
    FontAwesomeModule,
    NgxMatColorPickerModule,
    NgxMaskModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatTableModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    NgxSpinnerModule,
    ControlMessageComponent,
    ControlMessagesComponent,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatTableExporterModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    NgxMatSelectSearchModule,
    CdkStepperModule,
    NgStepperModule,
    MaterialFileInputModule,
    ErrorCardComponent,
    InfoCardComponent,
    PdfJsViewerModule,
    DefaultToPipe,
    // RegisterUserModalComponent,
    MatTreeModule,
    NgxEditorModule,
    FontAwesomeModule,
    SafeHtmlPipe,
    DatosSolicitanteComponent,
    DatosTitularComponent,
    DatosDomicilioComponent,
    AdjuntarDocumentacionComponent,
    CampoObservacionesComponent,
    PagoTasaComponent,
    PadronRmeComponent,
    PadronRmaComponent,
    PadronRmtsaComponent,
    PadronDtComponent,
    SolicitudRmaComponent,
    SolicitudRmeComponent,
    SolicitudRmtsaComponent,
    SolicitudDtComponent,
    DatosEstablecimientoComponent,
    DatosProfesionalesComponent,
    ObservacionesComponent,
    ChatTextComponent,
    HistorialInspeccionesComponent,
    DatosProfesionalesComponent,
    DomicilioCatastroComponent
  ],
  declarations: [
    ControlMessagesComponent,
    ControlMessageComponent,
    DialogsComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    MessagesComponent,
    /*
    AdvancedSearchComponent,
*/
    ErrorCardComponent,
    InfoCardComponent,
    DefaultToPipe,
    // RegisterUserModalComponent,
    SafeHtmlPipe,
    DatosSolicitanteComponent,
    DatosTitularComponent,
    DatosDomicilioComponent,
    DatosEstablecimientoComponent,
    DatosDireccionTecnicaComponent,
    AdjuntarDocumentacionComponent,
    CampoObservacionesComponent,
    PagoTasaComponent,
    AdvertenciaDialogComponent,
    PadronRmeComponent,
    PadronRmaComponent,
    PadronRmtsaComponent,
    PadronDtComponent,
    SolicitudDtComponent,
    SolicitudRmaComponent,
    SolicitudRmeComponent,
    SolicitudRmtsaComponent,
    InformacionDialogComponent,
    UsuarioSamDialogComponent,
    PerfilesDialogComponent,
    InfoTipoDocDialogComponent,
    RmeDialogComponent,
    InformacionHomeRmeDialogComponent,
    InformacionHomeRmtsaDialogComponent,
    RmeDialogComponent,
    DatosProfesionalesComponent,
    InformacionHomeRmdtDialogComponent,
    MisTramitesDialogComponent,
    NuevoDtDialogComponent,
    ContentChatsDialogComponent,
    ChatTextComponent,
    ErrorRmdtDialogComponent,
    TransferirTramiteFormComponent,
    ObservacionesComponent,
    AsignarTramiteDialogComponent,
    HistorialInspeccionesComponent,
    DomicilioCatastroComponent,
    DomicilioCatastroDialogComponent,
    ModalInfoInspeccionesComponent
  ],
  providers: [
    SnackBarService,
    FocusInvalidService,
    SnackBarService,
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginator },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    DatePipe
  ]
})
export class SharedModule {}
