import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ItemTasa } from '../models/tasas.model';
import { ComprobantePagoModel } from '../models/comprobante-pago.model';
import { PlataformaPagoModel } from '../models/plataforma-pago.model';
import { PagoModel } from '../models/pago.model';
import { PersonasSamFilter } from '../shared/filtros/personas-sam.filter';
import { CryptoService } from './crypto.service';

@Injectable({
  providedIn: 'root'
})
export class PagoTasasService {
  private _controllerName = 'pagos';

  constructor(private http: HttpClient, private cryptoService: CryptoService) {}

  public obtenerTasa(codigo): Observable<ItemTasa> {
    return this.http.get<ItemTasa>(`${this._controllerName}/obtenerTasa/?codigo=${codigo}`);
  }

  public generarBoleta(codigo: string): Observable<ComprobantePagoModel> {
    const body = {
      codigo
    };
    return this.http.post<ComprobantePagoModel>(`${this._controllerName}/generarBoleta`, body);
  }

  public generarPago(referencia, opcionPago, tramiteId): Observable<PlataformaPagoModel> {
    const body = {
      referencia,
      opcionPago,
      tramiteId
    };
    return this.http.post<PlataformaPagoModel>(`${this._controllerName}/generarPago`, body);
  }

  public consultarPago(idPago): Observable<PagoModel> {
    return this.http.get<PagoModel>(`${this._controllerName}/consultarPago/${idPago}`);
  }

  public existeUsuarioActivo(): Observable<any> {
    return this.http.post<any>(`${this._controllerName}/verificarUsuarioActivo`, {});
  }

  public generarUsuarioSam(datosPersona: PersonasSamFilter): Observable<any> {
    return this.http.post<PlataformaPagoModel>(`${this._controllerName}/generarUsuarioSam`, datosPersona);
  }
}
