import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ENVS } from '../../../environments/environments.constants';

@Injectable({
  providedIn: 'root'
})
export class ApiUrlInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('/assets')) return next.handle(req);
    const urlApi: string = environment.name !== ENVS.DEV ? '/api' : '';
    const request = req.clone({
      url: `${environment.apiUrl}${urlApi}/${req.url}`
    });
    return next.handle(request);
  }
}
