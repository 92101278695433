import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [ LayoutComponent ],
  imports: [ CommonModule, RouterModule, SharedModule, FlexLayoutModule ],
  providers: []
})
export class LayoutModule {}
